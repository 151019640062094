import React from "react";
import PropTypes from "prop-types";
import { Form } from "react-bootstrap";
import CustomTable from "../Components/CustomTable";
import PendingFavoriteCreate from "./Components/PendingFavoriteCreate";
import PendingFavoriteUpdate from "./Components/PendingFavoriteUpdate";
import FavoriteDelete from "./Components/FavoriteDelete";
import { sortFavorites, tableKeyTitles } from "./utils";
import FavoriteUpdateCompanies from "./Components/FavoriteUpdateCompanies";

const FavoritesTable = (props) => {
  const { user, favorites = [], onSuccess = () => {}, companies } = props;
  const visitTypes = user.visitTypes;

  const favoriteList = sortFavorites(favorites);

  const titleOverrides = {
    createUpdate: () => (
      <PendingFavoriteCreate
        user={user}
        onSuccess={onSuccess}
        visitTypes={visitTypes}
      />
    ),
  };

  const rowOverrides = {
    createUpdate: (_, favorite) => (
      <div className="favorite-button-container">
        <FavoriteUpdateCompanies
          user={user}
          favorite={favorite}
          onSuccess={onSuccess}
          companies={companies}
        />
        {favorite.type === "supply" ? null : (
          <PendingFavoriteUpdate
            user={user}
            favorite={favorite}
            onSuccess={onSuccess}
            visitTypes={visitTypes}
          />
        )}
        <FavoriteDelete user={user} favorite={favorite} onSuccess={onSuccess} />
      </div>
    ),
    allowSubstitutions: (value) => (
      <Form.Check disabled defaultChecked={value} />
    ),
  };

  return (
    <div className="favorites-page">
      <CustomTable
        list={favoriteList}
        getRowKey={(element) => element._id}
        tableKeyTitles={tableKeyTitles}
        titleOverrides={titleOverrides}
        rowOverrides={rowOverrides}
        companyName={user.companyName}
      />
    </div>
  );
};
FavoritesTable.propTypes = {
  user: PropTypes.object.isRequired,
  favorites: PropTypes.array.isRequired,
  onSuccess: PropTypes.func,
  companies: PropTypes.array.isRequired,
};

export default FavoritesTable;
