import React from "react";
import PropTypes from "prop-types";
import FavoriteModalFormButton from "./FavoriteModalFormButton";
import { addOrEditFavorite } from "../../utils/router";
import { generateRandomClientName } from "../utils";

const PendingFavoriteCreate = (props) => {
  const { user, onSuccess, visitTypes, pharmacies } = props;

  const defaultFormData = {
    clientName: generateRandomClientName(),
  };

  const handleFormSubmit = async (formData, setErrorMessage) => {
    const { token } = user;
    const res = await addOrEditFavorite(
      token,
      user.companyName,
      formData,
      setErrorMessage
    );
    if (res) {
      return res;
    }
    return false;
  };

  return (
    <FavoriteModalFormButton
      btnTitle="Create"
      modalTitle="Create Favorite"
      defaultFormData={defaultFormData}
      onFormSubmit={handleFormSubmit}
      onSuccess={onSuccess}
      visitTypes={visitTypes}
      pharmacies={pharmacies}
      mode="create"
      company={user.companyName}
    />
  );
};

PendingFavoriteCreate.propTypes = {
  user: PropTypes.object.isRequired,
  onSuccess: PropTypes.func.isRequired,
  visitTypes: PropTypes.array.isRequired,
};

export default PendingFavoriteCreate;
