const { ValidationSchema } = require("../../utils/validationSchema");
const favoriteSchema = {
  favoriteName: { type: "string", required: true },
  clientName: { type: "string", required: true },
  baskName: { type: "string", required: false },
  medication: { type: "string", required: true },
  type: { values: ["med", "compound"], required: true },
  strength: {
    type: "string",
    test: ({ strength, type }) => !(type === "med" && !strength),
    errorMessage: "Strength is required for medication type",
  },
  sig: { type: "string", required: true },
  quantity: {
    type: "number",
    required: true,
    test: ({ quantity }) => parseFloat(quantity) > 0,
    errorMessage: "Quantity must be greater than 0",
  },
  dispenseUnit: { type: "string", required: true },
  refills: { type: "number", required: true },
  daysSupply: {
    type: "number",
    required: true,
    test: ({ daysSupply }) => parseFloat(daysSupply) > 0,
    errorMessage: "Days supply must be greater than 0",
  },
  pharmacyNotes: { type: "string" },
  visitType: { type: "string", required: true },
  pharmacyId: {
    multipleValidation: true,
    type: "string",
    test: ({ pharmacyId, type }) => {
      if (type === "compound" && !pharmacyId) {
        return {
          errorMessage: "PharmacyId is required for compound",
        };
      }
      if (!/^[\d]*$/.test(pharmacyId)) {
        return {
          errorMessage: "PharmacyId must only contain digits",
        };
      }
      return {};
    },
  },
};

const favoriteValidator = new ValidationSchema(favoriteSchema);

module.exports = { favoriteSchema, favoriteValidator };
