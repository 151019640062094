import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { Form } from "react-bootstrap";
import FavoritesTable from "./FavoritesTable";
import CustomInput from "../Components/CustomInput";
import { sortFavorites, tableKeyTitles, isCompanyBask } from "./utils";
import usePharmacyNames from "../hooks/usePharmacyNames";

import "./Favorites.css";

const Favorites = (props) => {
  const { user, error, favorites, handleFetch } = props;

  const [medIdFilter, setMedIdFilter] = useState("");
  const [companyNameFilter, setCompanyNameFilter] = useState(null);
  const { pharmacyNames } = usePharmacyNames();

  const allSubCompanies = user.subcompanies;

  const mappedSubCompanies = allSubCompanies
    .sort((a, b) => a.localeCompare(b))
    .map((option) => (
      <option key={option} value={option}>
        {option}
      </option>
    ));

  useEffect(() => {
    handleFetch();
  }, []);

  const handleMedIdFilterChange = (e) => {
    setMedIdFilter(e.target.value);
  };

  const handleCompanyNameFilterChange = (e) => {
    setCompanyNameFilter(e.target.value);
  };

  const favoriteList = React.useMemo(() => {
    const filteredFavorites = favorites
      .filter((fav) => {
        if (!medIdFilter) return true;
        const queryField = isCompanyBask(user.companyName)
          ? "baskName"
          : "clientName";
        return fav[queryField].includes(medIdFilter);
      })
      .filter((fav) => {
        if (!companyNameFilter) return true;
        return fav.company.includes(companyNameFilter);
      });

    return sortFavorites(filteredFavorites);
  }, [favorites, companyNameFilter, medIdFilter]);

  const rowOverrides = {
    allowSubstitutions: (value) => (
      <Form.Check disabled defaultChecked={value} />
    ),
  };

  return (
    <div className="favorites-page">
      {error === "" ? (
        <>
          <div className="favorites-search-options">
            <CustomInput
              type="text"
              name="clientName"
              placeholder="Search by Medication ID"
              onChange={handleMedIdFilterChange}
            />
            <Form.Select
              name="Filter by subcompany"
              value={companyNameFilter ?? ""}
              onChange={handleCompanyNameFilterChange}
              className="formInput"
            >
              <option value="">Select subcompany</option>
              {mappedSubCompanies}
            </Form.Select>
          </div>
          <FavoritesTable
            user={user}
            favorites={favoriteList}
            getRowKey={(element) => element._id}
            tableKeyTitles={tableKeyTitles}
            onSuccess={handleFetch}
            rowOverrides={rowOverrides}
            companies={allSubCompanies}
            pharmacies={pharmacyNames}
          />
        </>
      ) : (
        <div className="error-container">
          <h1>{error}</h1>
        </div>
      )}
    </div>
  );
};

Favorites.propTypes = {
  user: PropTypes.object.isRequired,
};

export default Favorites;
